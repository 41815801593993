import React from "react";
import Layout from "../components/layout";
function LinkButton(props) {
  const { onClick, children } = props;
  return (
    <button
      type="button"
      style={{
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        textDecoration: "underline",
        display: "inline",
        margin: 0,
        padding: 0,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
export default function NotFound(props) {
  return (
    <Layout>
      <h1
      style={{'grid-column': 'span 2'}}
>Page not found</h1>
      <p
      style={{'grid-column': 'span 2'}}
>Oops the page you were looking for cannot be found.</p>
      <LinkButton onClick={() => window.history.back()}
      style={{'grid-column': 'span 2'}}
>Go back</LinkButton>
    </Layout>
  );
}
